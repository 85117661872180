import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import styles from './Survey.module.scss';
import { snakeToPascal } from '../../utils/caseconverters';
import Button from '../Button';
import CircleButton from '../CircleButton';

const Components = {
    FreeTextQuestion: dynamic(() => import('./FreeTextQuestion')),
    RatingQuestionGroup: dynamic(() => import('./RatingQuestionGroup')),
    SingleChoiceHorizontalQuestionGroup: dynamic(() =>
        import('./SingleChoiceHorizontalQuestionGroup')
    ),
    SingleChoiceVerticalQuestionGroup: dynamic(() =>
        import('./SingleChoiceVerticalQuestionGroup')
    ),
    StartQuestion: dynamic(() => import('./StartQuestion')),
};

const Survey = ({
    title,
    steps = [],
    slug,
    // defaultIsExpanded,
    thankYouText,
    userGuid,
    onClose,
    onDone,
    onSubmitAnswer,
}) => {
    if (!steps.length) {
        return null;
    }

    steps = steps || [];

    const [unsyncedAnswers, setUnsyncedAnswers] = useState([]);
    const [formValues, setFormValue] = useState({});
    const [isExpanded, setIsExpanded] = useState(true);
    const [stepIndex, setStepIndex] = useState(0);

    const handleExpandToggleClick = (_e) => {
        setIsExpanded(!isExpanded);
    };

    const handleChange = (question, answer) => {
        if (formValues[question] === answer) {
            return;
        }

        setFormValue((prevFormValues) => ({
            ...prevFormValues,
            [question]: answer,
        }));
        setUnsyncedAnswers((prevQuestions) => [...prevQuestions, question]);
    };

    const handleNext = () => {
        setTimeout(() => setStepIndex(stepIndex + 1), stepIndex ? 300 : 0);
    };

    const handleBackClick = () => {
        setStepIndex(stepIndex - 1);
    };

    const handleClose = () => {
        onClose(slug);
    };

    const isCompleted = stepIndex === steps.length;

    useEffect(() => {
        if (!unsyncedAnswers.length) {
            return;
        }

        [...new Set(unsyncedAnswers)].map((question) => {
            onSubmitAnswer({
                userGuid,
                surveySlug: slug,
                question: question,
                answer: formValues[question],
            });
        });

        setUnsyncedAnswers([]);
    }, [stepIndex]);

    useEffect(() => {
        if (!isCompleted) {
            return;
        }

        onDone(slug);
    }, [isCompleted]);

    if (isCompleted) {
        return (
            <div
                className={classNames(styles['Survey'], {
                    [styles['Survey--Expanded']]: isExpanded,
                })}>
                <button
                    className={classNames(styles['Survey__CloseButton'])}
                    onClick={handleClose}>
                    <span className="sr-only">
                        {i18n.t(
                            isExpanded
                                ? 'Survey.closeSurvey'
                                : 'Survey.openSurvey'
                        )}
                    </span>
                </button>

                {!isExpanded && (
                    <div className={styles['Survey__CollapsedTitle']}>
                        {title}
                    </div>
                )}
                {isExpanded && (
                    <div className={styles['Survey__Content']}>
                        <div className={styles['Survey__ThankYouStep']}>
                            <p className={styles['Survey__ThankYouStepText']}>
                                {thankYouText}
                            </p>
                            <Button
                                type="primary"
                                text={i18n.t('Survey.done')}
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    const step = steps[stepIndex];
    const componentName = snakeToPascal(step?.type);

    const StepComponent = Components[componentName];
    const stepProps = step.value;
    const headerTitle = stepProps.title;

    return (
        <div
            className={classNames(styles['Survey'], {
                [styles['Survey--Expanded']]: isExpanded,
                [styles['Survey--Collapsed']]: !isExpanded,
            })}>
            <button
                className={classNames(styles['Survey__CloseButton'])}
                onClick={handleClose}>
                <span className="sr-only">
                    {i18n.t(
                        isExpanded ? 'Survey.closeSurvey' : 'Survey.openSurvey'
                    )}
                </span>
            </button>

            {isExpanded && !isCompleted && (
                <div className={styles['Survey__Header']}>
                    <h3 className={styles['Survey__HeaderTitle']}>
                        {headerTitle}
                    </h3>
                </div>
            )}
            {isExpanded && (
                <div className={styles['Survey__Content']}>
                    <StepComponent
                        {...stepProps}
                        value={formValues[stepProps.title] || ''}
                        freeTextValue={
                            formValues[stepProps.title + ':FreeText'] || ''
                        }
                        onChange={handleChange}
                        onNext={handleNext}
                        onClose={handleClose}
                    />

                    <nav className={styles['Survey__Nav']}>
                        {stepIndex > 0 && (
                            <div className={styles['Survey__NavLeft']}>
                                <CircleButton
                                    label={i18n.t('Survey.next')}
                                    direction="left"
                                    onClickHandler={handleBackClick}
                                />
                            </div>
                        )}
                        {isExpanded && !isCompleted && (
                            <p className={styles['Survey__Steps']}>
                                <span className={'sr-only'}>
                                    {i18n.t('Survey.step')}
                                </span>
                                {stepIndex + 1}/{steps.length}
                            </p>
                        )}
                        {stepIndex >= 0 && (
                            <div className={styles['Survey__NavRight']}>
                                <CircleButton
                                    label={i18n.t('Survey.next')}
                                    direction="right"
                                    onClickHandler={handleNext}
                                />
                            </div>
                        )}
                    </nav>
                </div>
            )}
        </div>
    );
};

Survey.propTypes = {
    title: PropTypes.string,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            id: PropTypes.string,
            value: PropTypes.object,
        })
    ).isRequired,
    slug: PropTypes.string,
    defaultIsExpanded: PropTypes.bool,
    thankYouText: PropTypes.string,
    userGuid: PropTypes.string,
    onClose: PropTypes.func,
    onDone: PropTypes.func,
    onSubmitAnswer: PropTypes.func,
};

export default Survey;
