import Cookies from 'js-cookie';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { PureComponent, createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Breadcrumbs from '../../components/Breadcrumbs';
import EmergencyMessage from '../../components/EmergencyMessage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Survey from '../../components/Survey';
import WagtailUserbar from '../../components/WagtailUserbar';
import i18n from '../../i18n';
import {
    keysToCamelFromSnake,
    keysToSnakeFromCamel,
} from '../../utils/caseconverters';
import { trackEvent, dataLayerPush } from '../../utils/datalayer';
import styles from './BasePage.module.scss';

let surveyInitiated = false;

export const SiteSettingContext = createContext(null);

export default class BasePage extends PureComponent {
    state = {};

    static defaultProps = {
        seo: {},
    };

    static propTypes = {
        children: PropTypes.node,
        seo: PropTypes.shape({
            seoHtmlTitle: PropTypes.string,
            seoMetaDescription: PropTypes.string,
            seoOgTitle: PropTypes.string,
            seoOgDescription: PropTypes.string,
            seoOgUrl: PropTypes.string,
            seoTwitterTitle: PropTypes.string,
            seoMetaRobots: PropTypes.string,
        }),
        wagtailUserbar: PropTypes.shape({
            html: PropTypes.string,
        }),
        siteSetting: PropTypes.object,
        languageEquivalent: PropTypes.string,
    };

    componentDidMount() {
        window.survey = {
            init: async (surveySlug, force = false) => {
                const hasCompletedSurvey = !!Cookies.get(
                    'siteSurveyCompleted_' + surveySlug
                );

                const hasSeenSurveyThisSession = Cookies.get(
                    'hasSeenSurveyThisSession'
                );

                if (
                    (surveyInitiated ||
                        hasCompletedSurvey ||
                        hasSeenSurveyThisSession) &&
                    !force
                ) {
                    return;
                }

                surveyInitiated = true;

                if (!Cookies.get('siteSurveyUserGuid')) {
                    Cookies.set('siteSurveyUserGuid', uuidv4(), {
                        expires: 182,
                    });
                }

                const userGuid = Cookies.get('siteSurveyUserGuid');
                const resp = await fetch(
                    `/api/sitesurvey/v1/surveys/${surveySlug}/`
                );
                let json = await resp.json();
                json = keysToCamelFromSnake(json);

                Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed

                trackEvent(
                    'surveyInit',
                    'Survey',
                    'Survey initialized',
                    surveySlug
                );

                this.setState({
                    survey: {
                        ...json,
                        userGuid,
                    },
                });
            },
        };



        const pageViews = parseInt(sessionStorage.getItem('page_views') || '0') + 1;
        sessionStorage.setItem('page_views', pageViews);

        if (pageViews === 3) {
            dataLayerPush({ event: 'threePageViews' });
        }
    }

    handleSurveyDone = (surveySlug) => {
        surveyInitiated = false;
        Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed
        Cookies.set('siteSurveyCompleted_' + surveySlug, 1, {
            expires: 182,
        });
    };

    handleSurveyClose = (surveySlug) => {
        surveyInitiated = false;
        Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed
        Cookies.set('siteSurveyCompleted_' + surveySlug, 1, {
            expires: 182,
        });
        this.setState({ survey: null });
    };

    handleSurveySubmitAnswer = async ({
        surveySlug,
        question,
        answer,
        userGuid,
    }) => {
        let postData = {
            userGuid,
            question,
            answer,
            fromPage: window.location.href.slice(0, 255),
            fromDevice: window.navigator.userAgent.slice(0, 100),
        };
        postData = keysToSnakeFromCamel(postData);

        const resp = await fetch(
            `/api/sitesurvey/v1/surveys/${surveySlug}/answers/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(postData),
            }
        );
        const json = await resp.json();
    };

    render() {
        const {
            children,
            footer,
            seo,
            wagtailUserbar,
            header,
            breadcrumbs,
            siteSetting,
            languageEquivalent
        } = this.props;
        const {
            seoHtmlTitle,
            seoMetaDescription,
            seoOgTitle,
            seoOgDescription,
            seoOgUrl,
            seoOgImage,
            seoOgType,
            seoTwitterTitle,
            seoTwitterDescription,
            seoTwitterUrl,
            seoTwitterImage,
            seoMetaRobots,
            canonicalLink,
        } = seo;
        const { survey } = this.state;

        return (
            <SiteSettingContext.Provider value={siteSetting}>
                <Head>
                    <title>{seoHtmlTitle}</title>
                    <link
                        rel="icon"
                        type="image/png"
                        href="/White_Favicon_32px.png"
                        media="(prefers-color-scheme: dark"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/Black_Favicon_32px.png"
                        media="(prefers-color-scheme: light"
                    />
                    {!!seoMetaDescription && (
                        <meta name="description" content={seoMetaDescription} />
                    )}
                    {!!seoOgTitle && (
                        <meta property="og:title" content={seoOgTitle} />
                    )}
                    {!!seoOgDescription && (
                        <meta
                            property="og:description"
                            content={seoOgDescription}
                        />
                    )}
                    {!!seoOgUrl && (
                        <meta property="og:url" content={seoOgUrl} />
                    )}
                    {!!seoOgImage && (
                        <meta property="og:image" content={seoOgImage} />
                    )}
                    {!!seoOgType && (
                        <meta property="og:type" content={seoOgType} />
                    )}
                    {!!seoTwitterTitle && (
                        <meta
                            property="twitter:title"
                            content={seoTwitterTitle}
                        />
                    )}
                    {!!seoTwitterDescription && (
                        <meta
                            property="twitter:description"
                            content={seoTwitterDescription}
                        />
                    )}
                    {!!seoTwitterUrl && (
                        <meta property="twitter:url" content={seoTwitterUrl} />
                    )}
                    {!!seoTwitterImage && (
                        <meta
                            property="twitter:image"
                            content={seoTwitterImage}
                        />
                    )}
                    <meta name="robots" content={seoMetaRobots} />
                    {!!canonicalLink && (
                        <link rel="canonical" href={canonicalLink} />
                    )}
                </Head>
                <div className={styles['BasePage']}>
                    <a href="#main" suppressHydrationWarning={true}>
                        <span className="sr-only" suppressHydrationWarning>
                            {i18n.t('generic.mainContent')}
                        </span>
                    </a>
                    {!!header && <Header {...header} languageEquivalent={languageEquivalent} />}
                    {!!breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
                    {!!siteSetting?.emergencyMessage && (
                        <EmergencyMessage {...siteSetting.emergencyMessage} />
                    )}
                    <main id="main">{children}</main>
                    {!!footer && (
                        <Footer {...footer} siteSetting={siteSetting} />
                    )}
                </div>
                {wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
                {survey && (
                    <Survey
                        {...survey}
                        onClose={this.handleSurveyClose}
                        onDone={this.handleSurveyDone}
                        onSubmitAnswer={this.handleSurveySubmitAnswer}
                    />
                )}
            </SiteSettingContext.Provider>
        );
    }
}
