import classNames from 'classnames';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Headroom from 'react-headroom';
import i18n from '../../i18n';
import { countSavedItems } from '../../utils/MyListStorage';
import { isolateTabs } from '../../utils/a11y';
import { dataLayerPush } from '../../utils/datalayer';
import { capitalizeFirstLetter } from '../../utils/helpers';
import DropdownLinks from '../DropdownLinks';
import Icon from '../Icon';
import LabeledIcon from '../LabeledIcon';
import SearchModal from '../SearchModal';
import styles from './Header.module.scss';
import Card from '../Card';

const Heart = dynamic(() =>
    import('../../public/assets/img/icon-heart.svg').then(
        (mod) => mod.ReactComponent
    )
);
const MainNavItem = ({
    id,
    href,
    label,
    active,
    children,
    spotlightImages,
    onClick,
    showMegaMenu,
    expanded,
}) => {
    let withHeartLabel = null;

    const handleClick = (e) => {
        if (!showMegaMenu) {
            e.preventDefault();

            dataLayerPush({
                event: 'menu',
                eventCategory: 'Menu',
                eventAction: '1',
                eventLabel: capitalizeFirstLetter(label),
            });

            window.location.href = href;
        } else {
            onClick(id, href, label, children, spotlightImages);
        }
    };

    if (label === 'I <3 Sthlm') {
        withHeartLabel = (
            <span
                aria-label="I Love Stockholm"
                className={
                    styles['Header__MainNavItemLabel'] +
                    ' ' +
                    styles['Header__MainNavItemLabelHeart']
                }>
                <span>I</span> <Heart className={styles.HeaderHeart} />{' '}
                <span>Sthlm</span>
            </span>
        );
    }
    if (label === 'Jag <3 Sthlm') {
        withHeartLabel = (
            <span
                aria-label="Jag Älskar Stockholm"
                className={
                    styles['Header__MainNavItemLabel'] +
                    ' ' +
                    styles['Header__MainNavItemLabelHeart']
                }>
                <span>Jag</span> <Heart className={styles.HeaderHeart} />{' '}
                <span>Sthlm</span>
            </span>
        );
    }

    const commonProps = {
        className: classNames(styles['Header__MainNavItemLink'], {
            [styles['Header__MainNavItemLink--Active']]: active && expanded,
            [styles['Header__MainNavItemLink--MegaMenu']]: showMegaMenu,
        }),
        onClick: handleClick,
    };

    return (
        <li className={styles['Header__MainNavItem']}>
            {showMegaMenu ? (
                <button {...commonProps} type="button">
                    {withHeartLabel}
                    {!withHeartLabel && (
                        <span className={styles['Header__MainNavItemLabel']}>
                            {label}
                            <Icon
                                type="chevron"
                                size="small"
                                modifiers={[
                                    styles['Header__MainNavItemIcon'],
                                    active &&
                                        expanded &&
                                        styles['Header__MainNavItemIcon--Open'],
                                ]}
                            />
                            <div
                                className={
                                    styles['Header__MainNavItemChevronMobile']
                                }>
                                <Icon type="chevron" size="large" />
                            </div>
                        </span>
                    )}
                    {active && (
                        <span className="sr-only">
                            ({i18n.t('generic.current')})
                        </span>
                    )}
                    <span
                        className={styles['Header__MainNavItem--Hover']}></span>
                </button>
            ) : (
                <a {...commonProps} href={href}>
                    {withHeartLabel}
                    {!withHeartLabel && (
                        <span className={styles['Header__MainNavItemLabel']}>
                            {label}
                        </span>
                    )}
                    {active && (
                        <span className="sr-only">
                            ({i18n.t('generic.current')})
                        </span>
                    )}
                    <span
                        className={styles['Header__MainNavItem--Hover']}></span>
                </a>
            )}
        </li>
    );
};

MainNavItem.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

const MegaMenu = ({
    title = '',
    href,
    children = [],
    spotlightImages = [],
    onBackClick,
}) => {
    return (
        <div className={styles['Header__MegaMenu']}>
            <div className={styles['Header__MegaMenuWrapper']}>
                <div className={styles['Header__MegaMenuContainer']}>
                    <div className={styles['Header__MegaMenuLeft']}>
                        <button
                            onClick={onBackClick}
                            className={styles['Header__MegaMenuBackButton']}>
                            <Icon type="arrow" size="large" />
                            {i18n.t('Header.back')}
                        </button>
                        <a
                            href={href}
                            className={styles['Header__MegaMenuTitleWrapper']}>
                            <div
                                className={
                                    styles['Header__MegaMenuTitleContainer']
                                }>
                                <span
                                    className={styles['Header__MegaMenuTitle']}>
                                    {title}
                                </span>
                                <Icon
                                    type="arrow"
                                    size="large"
                                    modifiers={[
                                        styles['Header__MegaMenuTitleIcon'],
                                    ]}
                                />
                            </div>
                            <div
                                className={
                                    styles['Header__MegaMenuTitle--Hover']
                                }></div>
                        </a>
                        <ul className={styles['Header__MegaMenuLinks']}>
                            {children.map((child, i) => (
                                <li
                                    key={i}
                                    className={
                                        styles['Header__MegaMenuLinkWrapper']
                                    }>
                                    <a
                                        className={
                                            styles['Header__MegaMenuLink']
                                        }
                                        href={child.href}>
                                        {child.label}
                                    </a>
                                    <div
                                        className={
                                            styles[
                                                'Header__MegaMenuLink--Hover'
                                            ]
                                        }></div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {spotlightImages && (
                        <div className={styles['Header__MegaMenuRight']}>
                            <div
                                className={
                                    styles['Header__MegaMenuImageContainer']
                                }>
                                {spotlightImages.map((item, i) => (
                                    <Card
                                        useLazyLoad={false}
                                        {...item}
                                        size={'xSmall'}
                                        modifiers={[
                                            styles['Header__MegaMenuCard'],
                                            spotlightImages.length === 1
                                                ? styles[
                                                      'Header__MegaMenuCard--Single'
                                                  ]
                                                : i === 0
                                                ? styles[
                                                      'Header__MegaMenuCard--Half'
                                                  ]
                                                : i === 1
                                                ? styles[
                                                      'Header__MegaMenuCard--Full'
                                                  ]
                                                : '',
                                        ]}
                                        key={i}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

MegaMenu.propTypes = {
    title: PropTypes.string,
    children: PropTypes.array,
};

const Header = ({
    mainNav = [],
    navActivePages = [],
    languages = [],
    search = { href: '' },
    bookmark = { label: '', href: '' },
    publicApi = false,
    languageEquivalent,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [myListCounter, setMyListCounter] = useState(0);
    const [activeSearchModal, setActiveSearchModal] = useState(false);
    const [shouldAnimateSlideUp, setShouldAnimateSlideUp] = useState(false);
    const [shouldAnimateSlideUpMegaMenu, setShouldAnimateSlideUpMegaMenu] =
        useState(false);
    const [isSearchPage, setIsSearchPage] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState(null);

    const headerNavRef = useRef();

    const removeElement = () => {
        const element = document.getElementById('remove');
        element.style.transform = '';
    };

    useEffect(() => {
        window.onload = removeElement;
    }, []);

    useEffect(() => {
        if (!search.href) {
            return;
        }

        const currentPage = new URL(window.location.href);
        const searchPage = new URL(search.href);

        setIsSearchPage(currentPage.pathname === searchPage.pathname);
    }, []);

    useEffect(() => {
        setMyListCounter(countSavedItems());
        window.addEventListener('myListUpdated', () =>
            setMyListCounter(countSavedItems())
        );

        function keyListener(e) {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        }

        document.addEventListener('keydown', keyListener);

        return () => document.removeEventListener('keydown', keyListener);
    }, []);

    const menuToggleRef = useRef();
    const mobileMenuRef = useRef();

    const closeMenu = () => {
        if (menuToggleRef) {
            menuToggleRef.current.focus();
        }
        setExpanded(false);
    };
    const handleTabKey = (e) => {
        isolateTabs(e, mobileMenuRef);
    };

    const keyListenersMap = new Map([
        [27, closeMenu],
        [9, handleTabKey],
    ]);

    const handleSearchModal = (e) => {
        if (!isSearchPage) {
            setShouldAnimateSlideUp(true);
            setActiveSearchModal(() => !activeSearchModal);
        }
        if (!activeSearchModal) {
            setTimeout(() => {
                document.querySelector('#search_input').focus();
            }, 5);
        }
    };
    const handleNavItemClick = (id, href, label, children, spotlightImages) => {
        setActiveNavItem({ id, href, label, children, spotlightImages });
        setExpanded(true);

        if (activeNavItem?.href === href) {
            setExpanded(false);
            setShouldAnimateSlideUpMegaMenu(true);

            setTimeout(() => {
                setActiveNavItem(null);
                setShouldAnimateSlideUpMegaMenu(false);
            }, 200);
        }
    };

    useEffect(() => {
        if (!!activeSearchModal) {
            setExpanded(false);

            if (expanded) {
                setShouldAnimateSlideUpMegaMenu(true);
            }

            setShouldAnimateSlideUp(true);

            setTimeout(() => {
                setActiveNavItem(null);
            }, 200);
        }
    }, [activeSearchModal]);

    return (
        <>
            <div className={styles['PatchBlack']} />
            <Headroom
                id="remove"
                style={{ zIndex: 100002 }}
                onUnfix={removeElement}>
                <header
                    className={classNames(styles['Header'], {
                        [styles['Header--PublicApi']]: !!publicApi,
                        [styles['Header--ActiveSearchModal']]:
                            !!activeSearchModal,
                        [styles['Header--HideSearchModal']]:
                            !activeSearchModal && !!shouldAnimateSlideUp,
                    })}>
                    <div
                        className={styles['Header__ServiceMenu']}
                        ref={headerNavRef}>
                        <div className={styles['Header__ServiceMenuInner']}>
                            {!!search.href && (
                                <div
                                    className={
                                        styles['Header__ServiceMenuIconWrapper']
                                    }
                                    onClick={handleSearchModal}>
                                    <Icon
                                        type="search"
                                        color="white"
                                        size="small"
                                        modifiers={[
                                            styles['Header__ServiceMenuIcon'],
                                        ]}
                                    />
                                    <span
                                        className={
                                            styles[
                                                'Header__ServiceMenuIconLabel'
                                            ]
                                        }>
                                        {i18n.t('Header.search')}
                                    </span>
                                </div>
                            )}
                            {bookmark.href && (
                                <a
                                    href={bookmark.href}
                                    className={
                                        styles['Header__ServiceMenuIconWrapper']
                                    }>
                                    <Icon
                                        type="bookmark"
                                        color="white"
                                        size="small"
                                        modifiers={[
                                            styles['Header__ServiceMenuIcon'],
                                        ]}
                                    />
                                    <span
                                        className={
                                            styles[
                                                'Header__ServiceMenuIconLabel'
                                            ]
                                        }>
                                        {i18n.t('Header.list')}
                                    </span>
                                </a>
                            )}
                            {!!languages && !!languages.length && (
                                <div
                                    className={
                                        styles['Header__LanguageDesktop']
                                    }>
                                    <DropdownLinks
                                        items={languages}
                                        languageEquivalent={languageEquivalent}
                                        isSmall
                                        icon="web"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <nav
                        className={styles['Header__Nav']}
                        aria-label={i18n.t('Nav.main')}>
                        <div className={styles['Header__Inner']}>
                            <div className={styles['Header__Brand']}>
                                <a
                                    className={styles['Header__BrandLink']}
                                    href="/">
                                    <span
                                        suppressHydrationWarning
                                        className="sr-only">
                                        {i18n.t('Header.home')}
                                    </span>
                                </a>
                                <img
                                    width={151}
                                    height={36}
                                    src="/assets/img/logo-white.svg"
                                    alt="Stockholm - capital of Scandinavia: Logo"
                                    className={styles['Header__Logo']}
                                />
                                {!!publicApi && (
                                    <span
                                        className={
                                            styles['Header__BrandTitle']
                                        }>
                                        {i18n.t('Header.openApiBrandText')}
                                    </span>
                                )}
                            </div>

                            <div
                                id="navbarContent"
                                className={styles['Header__Content']}>
                                <ul
                                    className={classNames(
                                        styles['Header__MainNav--Desktop']
                                    )}>
                                    {mainNav.map((x, i) => {
                                        const showMegaMenu = x.showMegaMenu;
                                        return (
                                            <MainNavItem
                                                key={i}
                                                {...x}
                                                label={x.label}
                                                active={
                                                    activeNavItem?.id === x.id
                                                }
                                                expanded={expanded}
                                                onClick={handleNavItemClick}
                                                showMegaMenu={showMegaMenu}
                                            />
                                        );
                                    })}
                                </ul>
                                <div
                                    className={
                                        styles['Header__MobileServiceMenu']
                                    }>
                                    {!!search.href && (
                                        <span
                                            className={
                                                styles['Header__SearchButton']
                                            }>
                                            <LabeledIcon
                                                type="search"
                                                label={i18n.t('Header.search')}
                                                color={'white'}
                                                modifiers={[
                                                    styles[
                                                        'Header__LabeledIcon'
                                                    ],
                                                ]}
                                                onClick={handleSearchModal}
                                                extraLabelMod={[
                                                    styles[
                                                        'Header__CenteredLabel'
                                                    ],
                                                ]}
                                            />
                                        </span>
                                    )}

                                    {bookmark.href && (
                                        <div
                                            className={
                                                styles[
                                                    'Header__LabeldIconContainer'
                                                ]
                                            }>
                                            <LabeledIcon
                                                aria-live="polite"
                                                type={
                                                    myListCounter
                                                        ? 'bookmarkFilled'
                                                        : 'bookmark'
                                                }
                                                href={bookmark.href}
                                                extraLabel={
                                                    myListCounter &&
                                                    myListCounter > 0
                                                        ? myListCounter.toString()
                                                        : ''
                                                }
                                                label={i18n.t('Header.list')}
                                                color="white"
                                                modifiers={[
                                                    styles[
                                                        'Header__LabeledIcon'
                                                    ],
                                                ]}
                                                extraLabelMod={[
                                                    styles[
                                                        'Header__SavedCounter'
                                                    ],
                                                ]}
                                            />
                                        </div>
                                    )}

                                    {!!languages && !!languages.length && (
                                        <div
                                            className={
                                                styles[
                                                    'Header__LanguageDesktop'
                                                ]
                                            }>
                                            <DropdownLinks
                                                items={languages}
                                                languageEquivalent={
                                                    languageEquivalent
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <button
                                        className={styles['Header__Toggle']}
                                        ref={menuToggleRef}
                                        onClick={() => {
                                            setExpanded(!expanded);
                                        }}
                                        type="button"
                                        aria-controls="navbarContent"
                                        aria-expanded={expanded}
                                        aria-label={i18n.t('Header.toggleNav')}>
                                        <span
                                            className={
                                                styles[
                                                    'Header__ToggleBurgerWrapper'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    styles[
                                                        'Header__ToggleBurger'
                                                    ] +
                                                    ' ' +
                                                    styles[
                                                        'Header__ToggleBurger--1'
                                                    ]
                                                }></span>
                                            <span
                                                className={
                                                    styles[
                                                        'Header__ToggleBurger'
                                                    ] +
                                                    ' ' +
                                                    styles[
                                                        'Header__ToggleBurger--2'
                                                    ]
                                                }></span>
                                            <span
                                                className={
                                                    styles[
                                                        'Header__ToggleBurger'
                                                    ] +
                                                    ' ' +
                                                    styles[
                                                        'Header__ToggleBurger--3'
                                                    ]
                                                }></span>
                                        </span>
                                    </button>
                                    <div
                                        className={
                                            styles['Header__ToggleLabel']
                                        }>
                                        {i18n.t('Header.menu')}
                                    </div>
                                </div>
                                <div
                                    ref={mobileMenuRef}
                                    className={classNames(
                                        styles['Header__ExpandedMenu'],
                                        {
                                            [styles[
                                                'Header__ExpandedMenu--Hide'
                                            ]]: !expanded,
                                            [styles[
                                                'Header__ExpandedMenu--MegaMenuOpen'
                                            ]]: !!activeNavItem,
                                        }
                                    )}>
                                    <div
                                        className={
                                            styles['Header__MobileHeader']
                                        }>
                                        <div
                                            className={
                                                styles[
                                                    'Header__MobileHeaderHeadline'
                                                ]
                                            }>
                                            {i18n.t('Menu.menu')}
                                        </div>
                                        {!publicApi &&
                                            !!languages &&
                                            !!languages.length && (
                                                <div
                                                    className={
                                                        styles[
                                                            'Header__LanguageMobile'
                                                        ]
                                                    }>
                                                    <DropdownLinks
                                                        items={languages}
                                                        languageEquivalent={
                                                            languageEquivalent
                                                        }
                                                        modifier="mobile"
                                                    />
                                                </div>
                                            )}
                                        {expanded && (
                                            <button
                                                autoFocus
                                                className={
                                                    styles[
                                                        'Header__MobileHeader--Close'
                                                    ]
                                                }
                                                onClick={() => {
                                                    setExpanded(!expanded);
                                                }}>
                                                <span className="sr-only">
                                                    {i18n.t('Menu.close')}
                                                </span>
                                                <Icon
                                                    type="close"
                                                    color="white"
                                                    size="large"
                                                />
                                            </button>
                                        )}
                                    </div>

                                    {mainNav.length > 0 && (
                                        <ul
                                            className={classNames(
                                                styles['Header__MainNav'],
                                                {
                                                    [styles[
                                                        'Header__MainNav--Expanded'
                                                    ]]: !!expanded,
                                                }
                                            )}>
                                            {expanded && activeNavItem ? (
                                                <div
                                                    className={
                                                        styles[
                                                            'Header__MegaMenuMobile'
                                                        ]
                                                    }>
                                                    <MegaMenu
                                                        title={
                                                            activeNavItem.label
                                                        }
                                                        href={
                                                            activeNavItem.href
                                                        }
                                                        children={
                                                            activeNavItem.children
                                                        }
                                                        spotlightImages={
                                                            activeNavItem.spotlightImages
                                                        }
                                                        onBackClick={() =>
                                                            setActiveNavItem(
                                                                null
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                mainNav.map((x, i) => {
                                                    const showMegaMenu =
                                                        x.showMegaMenu;
                                                    return (
                                                        <MainNavItem
                                                            key={i}
                                                            {...x}
                                                            active={navActivePages.includes(
                                                                x?.id
                                                            )}
                                                            onClick={
                                                                handleNavItemClick
                                                            }
                                                            showMegaMenu={
                                                                showMegaMenu
                                                            }
                                                        />
                                                    );
                                                })
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </nav>
                    {!isSearchPage && !!search.href && (
                        <div
                            className={classNames(
                                styles['Header__SearchModalWrapper'],
                                {
                                    [styles[
                                        'Header__SearchModalWrapper--Active'
                                    ]]: !!activeSearchModal,
                                    [styles[
                                        'Header__SearchModalWrapper--Hide'
                                    ]]:
                                        !activeSearchModal &&
                                        !!shouldAnimateSlideUp,
                                }
                            )}>
                            <SearchModal
                                searchUrl={search.href}
                                closeModal={() => setActiveSearchModal(false)}
                                headerNavRef={headerNavRef}
                            />
                        </div>
                    )}

                    <div
                        className={classNames(
                            styles['Header__MegaMenuDesktop'],
                            {
                                [styles['Header__MegaMenuDesktop--Active']]:
                                    expanded,
                                [styles['Header__MegaMenuDesktop--Hide']]:
                                    !expanded && shouldAnimateSlideUpMegaMenu,
                            }
                        )}>
                        <MegaMenu
                            title={activeNavItem?.label}
                            href={activeNavItem?.href}
                            children={activeNavItem?.children}
                            spotlightImages={activeNavItem?.spotlightImages}
                        />
                    </div>
                </header>
            </Headroom>
        </>
    );
};

Header.propTypes = {
    mainNav: PropTypes.array,
    navActivePages: PropTypes.array,
    languages: PropTypes.array,
    search: PropTypes.object,
    bookmark: PropTypes.object,
    publicApi: PropTypes.bool,
};

export default Header;
